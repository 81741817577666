/**
 *
 * About Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    aboutData, 
} from "../../data/PageAssets";

export default class AboutPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                // lazy loading 
        this._assetPath = "about";          // asset folder for page
        this._assets = aboutData();         // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        if (this.isBrowser) this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz } = this.state;
        const { hasResized: rz } = this.props;

        const getVid = this.pVideoNam("about_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page about"}>
                    <div className={"page-content"}>
                        <h1>About “Rehabilitation Redefined.”</h1>
                        <p>A new concept in luxury rehabilitation, Casa Lupita is an all-inclusive and fully accessible 4 Bedrooms, 6 Baths villa designed to accommodate 9 Guests.</p>
                        <p>Situated only 300 meters above Playa Bocana, next to the unspoiled estuary of the Rio Copalita, 
                        Casa Lupita is the brainchild of Dr. Andrew Glassman, who has dedicated two decades of his life to 
                        helping children and families in need, through his Lupita Project, a not for profit charitable 
                        foundation based in Santa Maria Huatulco, Mexico.</p>
                        <p>Devoted to enriching the lives of those who suffer disabling infirmities, the non for profit organization has brought the gift 
                        of comfort and mobility, as well as the dignity of independence, to countless families.</p>
                        <p>The Lupita project is a non-profit that has been working in close collaboration with the DIF, a Mexican 
                        public institution of social assistance, that focuses on strengthening and developing the welfare of Mexican 
                        families. The Lupita project has been providing a much needed safety net for children and adults, without 
                        access to resources, as well as  a range of vital services to those who live in the coastal region of
                        Oaxaca, Mexico.</p>
                        <p>Dr. Glassman’s project began with the notion that medical devices like canes, crutches and wheelchairs, 
                        with very little wear and tear, and which could no longer be rented, could in fact find a productive 
                        second life in Mexico.  The program has grown to offer specific synergistic areas of treatment, at no 
                        cost to its patients, through its alliance with Shriners Hospital for Children, as well as hydrotherapy 
                        and extending clinical physical therapy at home, for those patients for which transportation 
                        is a challenge.</p>
                        <p>You are encouraged to learn more about this incredible initiative and the difference it is making to the 
                        lives of so many families at <a href="https://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a></p>      
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("about_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="About"
                                    bodyHeading={`“Rehabilitation Redefined.”`}
                                    body={"A new concept in luxury rehabilitation, Casa Lupita is an all-inclusive and fully accessible 4 Bedrooms, 6 Baths villa designed to accommodate 9 Guests."}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" paddingBottom={0} {...mProps}>

                                <ImageLayer 
                                    classes="about-map"
                                    alt="Map location of Casa Lupita"
                                    asset={this.assetRef("about_map")}
                                    x={984} y={107} width={720} height={460}
                                />
                                
                                <TextLayer classes="large" titleHtml={"Casa Lupita"} x={290} y={120} width={600} height={530}>
                                    Situated only 300 meters above Playa Bocana, next to the unspoiled estuary of the Rio Copalita, Casa Lupita’s aim is to provide 
                                    a refuge and sanctuary to you, while offering an unparalleled five star vacation experience in a tropical paradise, with 
                                    first class rehabilitation services.  
                                    <p>Our goal during your stay, is to bring the dignity of independence and mobility to your child or loved one, and to 
                                        promote the mental and physical health of not just the patient, but of their families.</p>                                
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    alt="Collage. Two girls by the beach and trainers working a patient."
                                    asset={this.assetRef("trainers_working_patient")}
                                    x={0} y={0} width={1920} height={920}
                                />

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    mobile={{ order: 2 }}
                                    classes="about-path"
                                    alt="Beautiful mountain road leading to Casa Lupita"
                                    asset={this.assetRef("about_path_road")}
                                    x={0} y={0} width={1920} height={1250}
                                />

                                <TextLayer 
                                    mobile={{ order: 1 }}
                                    classes="like-no-place large wc-463"
                                    titleText={"Like no Place on Earth"}
                                    html={"Casa Lupita.&nbsp; Inclusive.&nbsp; Accessible.&nbsp; Unforgettable."}
                                    textAlign="center"
                                    x={688} y={115} width={628} height={184} >
                                </TextLayer>

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Casa Lupita's master bedroom"
                                    asset={this.assetRef("about_master_bedroom")}
                                    x={0} y={107} width={1920} height={1118}
                                />
                                
                                <ImageLayer 
                                    alt="Casa Lupita's balcony view with hammock"
                                    asset={this.assetRef("about_outside_hammock")}
                                    x={637} y={1028} width={1126} height={750}
                                />

                                <TextLayer classes="bottom-space large t-589" x={143} y={1320} width={435} height={300} >
                                    Casa Lupita offers all you might expect from a five-star luxury boutique hotel. With a wide assortment 
                                    of world-class amenities, set in a tropical paradise, you will relax, unwind and renew your body and soul, 
                                    while still having all the creatures comforts of “civilization” at your fingertips.
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} paddingBottom={107} {...mProps}>

                                <ImageLayer 
                                    alt="Casa Lupita's waterfall poolside angle"
                                    asset={this.assetRef("about_waterfall_poolside")}
                                    x={145} y={0} width={927} height={1069}
                                />
                                
                                <ImageLayer 
                                    classes="t-117"
                                    alt="Casa Lupita's master bedroom"
                                    asset={this.assetRef("about_masterbed_fireplace")}
                                    x={1189} y={249} width={595} height={594}
                                />
                               
                            </ModuleLayer>
                            
                            <ModuleLayer 
                                backgroundColor="white" 
                                {...mProps}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Casa Lupita top view edge"
                                    asset={this.assetRef("about_topview")}
                                    x={0} y={0} width={1920} height={1080}
                                />

                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>
                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}